.App {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.dayColumn {
  position: relative;
  height: 600px; /* Assuming each hour is 50px high and you're showing 12 hours */
  background-color: #fff;
  border-right: 2px solid #e0e0e0;
}

.dayColumn:last-child {
  border-right: none;
}

.hourBlock {
  position: absolute;
  width: 100%;
  height: 50px; /* This defines the height of each hour */
  border-bottom: 1px solid #e0e0e0;
}

.halfHour {
  position: absolute;
  width: 100%;
  height: 25px; /* Half the height of the hourBlock */
  border-bottom: 1px dashed #e0e0e0;
}

.course {
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(0, 150, 255, 0.7);
  border-radius: 4px;
  padding: 4px;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Optional, to ensure your grid is centered within the viewport */
.GridContainer {
  justify-content: center;
}

.custom-container {
  display: flex; /* trying to get rid of the space between them*/
  justify-content: space-between; 
}

/* App.css */

/* Reset default margins and padding to ensure consistency across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Use a system font stack for performance and user's system preferences */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #f5f5f5;
}

.App {
  text-align: center;
  padding: 20px;
  color: #333; /* Dark grey text for better readability */
}

/* Header styling */
.header {
  position: fixed;
  width: 100%;
  background-color: #ffffff; /* Light background for the header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1000; /* Ensure the header is above all other content */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px; /* Horizontal padding */
  height: 60px; /* Fixed header height */
}

/* Logo and title */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px; /* Logo height */
  margin-right: 10px; /* Space between logo and title */
}

.logo-title {
  font-size: 24px; /* Large font size for the title */
  font-weight: bold;
  color: #1a73e8; /* Primary color for branding */
}

/* Navigation buttons */
.nav-buttons {
  display: flex;
}

.nav-button {
  background-color: #1a73e8; /* Primary color */
  color: #ffffff; /* White text for contrast */
  border: none;
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Vertical and horizontal padding */
  margin-left: 10px; /* Space between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.nav-button:hover {
  background-color: #0f62fe; /* Slightly darker shade on hover */
}

/* Search bar */
.search-bar {
  position: relative;
  margin: 20px 0; /* Vertical margin for separation */
}

.search-input {
  width: 100%;
  max-width: 400px; /* Max width of the search input */
  padding: 10px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 20px; /* Fully rounded edges */
  font-size: 16px; /* Comfortable font size for reading */
}

.search-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1a73e8;
  color: #ffffff;
  border-top-right-radius: 20px; /* Rounded corner on the right */
  border-bottom-right-radius: 20px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #fe530f;
}

/* Calendar and schedule styling */
.calendar-container {
  padding-top: 80px; /* Add padding to the top to avoid overlap with the fixed header */
}

/* Add more styles as needed */

